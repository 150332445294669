import { OFFER_CLASS, OFFER_CLASS_REVERSE } from "./constants";

const offerCloseBtn = $(".offer__content-close");

const offerShowBtn = $(".offer__btn");

const showOfferDetails = (ev) => {
	$(".offer__card").removeClass(OFFER_CLASS);

	$(ev.target)
		.closest(".offer__card")
		.addClass(OFFER_CLASS)
		.siblings(".offer__card")
		.removeClass(OFFER_CLASS);
};

const hideOfferDetails = (ev) => {
	$(".offer__card").removeClass(OFFER_CLASS);
};

offerShowBtn.on("click", showOfferDetails);

offerCloseBtn.on("click", hideOfferDetails);
