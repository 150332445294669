import { HAMBURGER_CLASS, MENU_CLASS, SOCIALICONS_CLASS } from "./constants";

const hamburgerBtn = $(".hamburger");
const menu = $(".menu");
const socialIcons = $(".hero__socials");

export const toggleMenu = () => {
	hamburgerBtn.toggleClass(HAMBURGER_CLASS);
	menu.toggleClass(MENU_CLASS);
	socialIcons.toggleClass(SOCIALICONS_CLASS);
};

hamburgerBtn.on("click", toggleMenu);
