import { toggleMenu } from "./Menu";

const scrollPage = (ev) => {
	ev.preventDefault();

	const link = $(ev.target).closest("a").attr("href");
	const sectionToScroll = link?.substring(link.indexOf("#"), link.length);

	if (sectionToScroll === "#") {
		return;
	}

	if (!$(sectionToScroll).length && $(ev.target).closest(".menu").length) {
		window.location.href = wp_obj.site_url + sectionToScroll;
	}

	if ($(ev.target).closest(".menu").length) {
		toggleMenu();
	}

	$("html, body")
		.stop()
		.animate({ scrollTop: $(sectionToScroll).offset().top }, 500);
};

$(".scroll-link , .menu ul li a").on("click", scrollPage);
