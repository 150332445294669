const input = $(".material-input input, .material-input textarea");

input?.on({
	focusin: (ev) => {
		$(ev.target).parent().parent().find("label").addClass("is-focused");
	},
	focusout: (ev) => {
		if ($(ev.target).val().length === 0) {
			$(ev.target).parent().parent().find("label").removeClass("is-focused");
		}
	},
});
