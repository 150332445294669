import { HEADER_CLASS } from "./constants";

const header = $(".header");

const setHeaderBackground = () => {
	const isScrolled = $(window).scrollTop() > 0;
	return isScrolled
		? header.addClass(HEADER_CLASS)
		: header.removeClass(HEADER_CLASS);
};

$(window).on("scroll", setHeaderBackground);
